.navbar ul {
  list-style-type: none; /* Remove bullet points */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
}

.navbar ul {
  display: flex;
  gap: 64px;

  position: absolute;
  left: 50%; /* Move to the center */
  transform: translateX(-50%);
  top: 64px;
}

.navbar ul li {
  color: #fff;
  text-shadow: 0.933px 0.933px 1.958px rgba(242, 215, 69, 0.5);
  font-family: "Montserrat";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: max-content;

  cursor: pointer;
}

.mobile-navbar {
  display: none;
}

@media (min-width: 800px) {
  .navbar ul li {
    transition: color 0.3s ease; /* Smooth color transition */
    cursor: pointer; /* Change cursor to pointer (finger) on hover */
    position: relative;
  }
  /* Hover effect - change text color */
  .navbar ul li:hover {
    color: #ffc803; /* Change text color on hover */
  }

  /* Underline effect - hidden by default */
  .navbar ul li::after {
    content: "";
    position: absolute;
    width: 0; /* Start with no line */
    height: 3px; /* Height of the underline */
    background-color: #ffc803; /* Color of the underline */
    bottom: -5px; /* Position the line at the bottom */
    left: 50%; /* Start from the center */
    transition: width 0.3s ease, left 0.3s ease; /* Smoothly reveal the line */
  }

  /* On hover, reveal the underline */
  .navbar ul li:hover::after {
    width: 100%; /* Expand the line to full width */
    left: 0; /* Move to the left */
  }
}

@media (max-width: 850px) {
  .navbar ul li {
    color: #fff;
    text-shadow: 0.438px 0.438px 0.92px rgba(242, 215, 69, 0.5);
    font-family: "Montserrat";
    font-size: 6.266px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .navbar ul {
    gap: 30px;
    top: 30px;
  }
}

@media (max-width: 450px) {
  .navbar {
    display: none;
  }

  .triple {
    display: flex;
    width: 50px;
    flex-direction: column;
    gap: 4px;
    align-items: center;

    margin-top: 41px;
    margin-right: 30px;
    margin-left: auto;
    transition: align-items 0.4s ease;
    z-index: 9999;
    transition: align-items 0.4s ease;
  }

  .triple.active {
    align-items: flex-end;

    transition: align-items 0.4s ease;
  }

  .triple.active .t1,
  .triple.active .t2,
  .triple.active .t3 {
    background: linear-gradient(180deg, #fff -35.59%, #ffc803 123.73%);
  }

  .triple .t1 {
    width: 35px;
    height: 6.868px;
    flex-shrink: 0;
    border-radius: 169px;
    background: #fff;
  }
  .triple .t2 {
    width: 50px;
    height: 6.596px;
    flex-shrink: 0;
    border-radius: 169px;
    background: #fff;
  }
  .triple .t3 {
    width: 35px;
    height: 6.868px;
    flex-shrink: 0;
    border-radius: 169px;
    background: #fff;
  }

  .mobile-navbar {
    display: block;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 999;
  }

  .mobile-navbar.active {
    background: rgba(0, 0, 0, 0.29);
    backdrop-filter: blur(3.828124761581421px);
    width: 40%;
    height: 100%;
    z-index: 999;
  }

  .mobile-navbar ul {
    display: none;
  }

  .mobile-navbar ul.active {
    text-decoration: none;
    list-style: none;
    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: column;
    gap: 40px;
    justify-content: center;

    margin-top: 60px;
  }

  .mobile-navbar ul li,
  .mobile-navbar ul li a {
    color: #fff;
    text-shadow: 0.729px 0.729px 1.53px rgba(255, 255, 255, 0.5);
    font-family: "Montserrat";
    font-size: 10.417px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    text-decoration: none;
  }

  .mobile-navbar ul li:active {
    transform: scale(0.95); /* Scale down slightly when clicked */
    transition: transform 0.3s ease;
  }
}
