.main {
  background-color: #fff;
  background-image: url("../Assets/Main/back-ellipse.png"),
    url("../Assets//Main/back-btn.png"), url("../Assets//Main/back-shad.png"),
    url("../Assets//Main/image\ 29.jpg");

  background-repeat: no-repeat;
  background-position: bottom -550px center, bottom -150px center, center,
    top center;
  background-size: auto, contain, cover, cover;
}

.main .content {
  position: relative;
  padding-top: 200px;
  padding-bottom: 300px;
}

.main .icon {
  position: absolute;
  top: 42px;
  left: 0;
}

.main .number a {
  position: absolute;
  top: 64px;
  right: 0;

  color: #fff;
  text-shadow: 0.933px 0.933px 1.958px rgba(242, 215, 69, 0.5);
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}

.main .m1 {
  color: #fff;
  text-align: center;
  font-family: "Montserrat";
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -2.5px;

  width: 690px;
  margin: auto;
  margin-bottom: 28px;
}

.main .m2 {
  color: #fff;
  font-family: "Montserrat";
  font-size: 29px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  text-align: center;
  margin-bottom: 18px;
}

.main .m3 {
  color: #fff;
  text-align: center;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  width: 588px;
  margin: auto;
  margin-bottom: 80px;
}

.main .m4 {
  display: flex;
  justify-content: center;
  gap: 17px;
}

.main .m4 button {
  display: flex;
  width: 220px;
  height: 31px;
  justify-content: center;
  align-items: center;

  border: none;
  border-radius: 18px;
  background: linear-gradient(180deg, #fff -35.59%, #ffc803 123.73%);
  box-shadow: 0px -1.348px 3.908px 0px rgba(0, 0, 0, 0.25) inset,
    0px 0px 5.391px 0px rgba(255, 255, 255, 0.7);

  color: #000;
  font-family: "Montserrat";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.189px;

  cursor: pointer;
}

.main .m4 button.b2 {
  background: none;
  border: 1.333px solid #fff;

  color: #fff;
  box-shadow: none;
}

@media (max-width: 850px) {
  .main .content {
    padding-top: 120px;
    padding-bottom: 116px;
  }

  .main .icon {
    width: 29px;
    height: auto;
    top: 20px;
    left: 95px;
  }

  .main .number a {
    top: 30px;
    right: 95px;

    text-shadow: 0.438px 0.438px 0.92px rgba(242, 215, 69, 0.5);
    font-size: 7px;
  }

  .main .m1 {
    color: #fff;
    text-align: center;
    font-family: "Montserrat";
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1.4px;

    width: 400px;
    margin-bottom: 15px;
  }

  .main .m2 {
    color: #fff;
    font-family: "Montserrat";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.32px;
    margin-bottom: 9px;
  }

  .main .m3 {
    width: 294px;
    color: #fff;
    text-align: center;
    font-family: "Montserrat";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.24px;
    margin-bottom: 57px;
  }

  .main .m4 {
    gap: 14px;
  }

  .main .m4 button {
    width: 139.523px;
    height: 21px;
    flex-shrink: 0;

    border-radius: 14.237px;
    background: linear-gradient(180deg, #fff -35.59%, #ffc803 123.73%);
    box-shadow: 0px 0px 4.271px 0px rgba(255, 255, 255, 0.7),
      0px -1.068px 3.097px 0px rgba(0, 0, 0, 0.25) inset;

    color: #000;
    font-family: "Montserrat";
    font-size: 7.491px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.15px;
  }

  .main .m4 button.b2 {
    border-radius: 14.237px;
    border: 1.056px solid #fff;
  }
}

@media (max-width: 740px) {
  .main .icon {
    width: 29px;
    height: auto;
    top: 20px;
    left: 46px;
  }

  .main .number a {
    top: 30px;
    right: 46px;

    text-shadow: 0.438px 0.438px 0.92px rgba(242, 215, 69, 0.5);
    font-size: 7px;
  }
}

@media (max-width: 520px) {
  .main .icon {
    width: 29px;
    height: auto;
    top: 20px;
    left: 10px;
  }

  .main .number a {
    top: 30px;
    right: 10px;

    text-shadow: 0.438px 0.438px 0.92px rgba(242, 215, 69, 0.5);
    font-size: 7px;
  }
}

@media (max-width: 450px) {
  .main {
    background-color: #fff;
    background-image: url("../Assets/Main/back-ellipse-mob.png"),
      url("../Assets//Main/back-mob.png");

    background-repeat: no-repeat;
    background-position: bottom -70px center, center;
    background-size: auto, cover;
  }

  .main .content {
    padding-top: 145px;
    padding-bottom: 110px;
  }

  .main .number {
    display: none;
  }

  .main .icon {
    width: 44px;
    height: auto;
    top: 37px;
    left: 34px;
  }

  .main .m1 {
    color: #fff;
    text-align: center;
    font-family: "Montserrat";
    font-size: 35.363px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 35.363px */

    width: 297px;
    margin-bottom: 20px;
  }

  .main .m2 {
    width: 272px;
    color: #fff;
    text-align: center;
    font-family: "Montserrat";
    font-size: 20.079px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.402px;
    margin: auto;
    margin-bottom: 10px;
  }

  .main .m3 {
    width: 289px;
    color: #fff;
    text-align: center;
    font-family: "Montserrat";
    font-size: 12.239px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.245px;
    margin-bottom: 100px;
  }

  .main .m4 {
    gap: 15px;
    flex-direction: column;
    align-items: center;
  }

  .main .m4 button {
    display: flex;
    width: 160px;
    height: 32px;
    justify-content: center;
    align-items: center;

    border-radius: 17px;
    background: linear-gradient(180deg, #fff -35.59%, #ffc803 123.73%);
    box-shadow: 0px -1.135px 3.292px 0px rgba(0, 0, 0, 0.25) inset,
      0px 0px 4.541px 0px rgba(255, 255, 255, 0.7);

    color: #000;
    font-family: "Montserrat";
    font-size: 9px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.159px;
  }

  .main .m4 button.b2 {
    border-radius: 15.137px;
    border: 1.123px solid #fff;
  }
}
