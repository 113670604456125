@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

body {
  margin: 0;
  padding: 0;
}

.content {
  width: 1200px;
  margin: auto;
  max-width: 95%;
}

/* #### Button animation ### */
button {
  transition: transform 0.5s ease;
}

button:hover {
  cursor: pointer;
  transform: scale(1.05);
  transition: transform 0.5s ease;
}

/* Button click (active) animation */
button:active {
  transform: scale(0.95); /* Scale down slightly when clicked */
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.15); /* Reduce shadow during click */
}

/* Form */
.form-open {
  display: block;
}

.form-close {
  display: none;
}

.card-open {
  display: block;
}

.card-close {
  display: none;
}

.blur-whole {
  filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.5); /* Black with 50% opacity */
}
