.about-me {
  padding-top: 79px;
  padding-bottom: 82px;

  background: #4a4a4a;
  background-image: url("../Assets/AboutMe/Rectangle\ 25.png"),
    url("../Assets/AboutMe/astana\ 1\ \(Traced\).png");
  background-repeat: no-repeat;
  background-size: cover, cover;
  background-position: center, bottom -120px center;
}

.about-me .flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.about-me .a1 {
  color: #f2f1eb;
  font-family: "Montserrat";
  font-size: 25.524px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  margin-bottom: 10px;
}

.about-me .a2 {
  width: 392px;
  color: #f2f1eb;
  font-family: "Montserrat";
  font-size: 35.686px;
  font-style: normal;
  font-weight: 600;
  line-height: 101.927%; /* 36.373px */
  letter-spacing: -0.714px;

  margin-bottom: 14px;
}

.about-me .a3 {
  width: 510px;

  color: #f2f1eb;
  font-family: "Montserrat";
  font-size: 13.333px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.267px;

  margin-bottom: 43px;
}

.about-me .a4 {
  color: #f2f1eb;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.4px;

  margin-bottom: 18px;
}

.about-me .a5 {
  display: flex;
  gap: 9px;
  align-items: flex-start;

  margin-bottom: 12px;
}

.about-me .a5 .text {
  width: 240px;
  color: #f2f1eb;
  font-family: "Montserrat";
  font-size: 13.333px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.267px;
}

.about-me .a5 .text span {
  color: #f2f1eb;
  font-family: "Montserrat";
  font-size: 13.333px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.267px;
}

.about-me .a6 {
  display: flex;
  gap: 9px;
  align-items: flex-start;
  margin-bottom: 58px;
}

.about-me .a6 .text {
  width: 382px;
  color: #f2f1eb;
  font-family: "Montserrat";
  font-size: 13.333px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.267px;
}

.about-me .a6 .text span {
  color: #f2f1eb;
  font-family: "Montserrat";
  font-size: 13.333px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.267px;
}

.about-me button.a7 {
  display: flex;
  width: 200px;
  height: 28px;
  justify-content: center;
  align-items: center;

  border: none;
  border-radius: 20px;
  background: linear-gradient(180deg, #fff -35.59%, #ffc803 123.73%);

  color: #000;
  font-family: "Montserrat";
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.18px;

  cursor: pointer;
}

.about-me .right img {
  width: 100%;
}
@media (max-width: 1050px) {
  .about-me .right img {
    width: 100%;
    height: 400px;
    border-radius: 20px;
    /* Ensures the image covers the container */
    object-fit: cover;

    /* Centers the image inside the container */
    object-position: center;
  }
}

@media (max-width: 850px) {
  .about-me {
    padding-top: 29px;
    padding-bottom: 35px;

    background: #4a4a4a;
    background-image: url("../Assets/AboutMe/Rectangle\ 25.png"),
      url("../Assets/AboutMe/astana\ 1\ \(Traced\)-mob.png");
    background-repeat: no-repeat;
    background-size: cover, contain;
    background-position: center, bottom -10px center;
  }

  .about-me .a1 {
    color: #f2f1eb;
    font-family: "Montserrat";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    margin-bottom: 10px;
  }

  .about-me .right img {
    width: 339px;
    height: 353px;
    border-radius: 12px;

    /* Ensures the image covers the container */
    object-fit: cover;

    /* Centers the image inside the container */
    object-position: center;
  }

  .about-me .a2 {
    width: 266.102px;
    color: #f2f1eb;
    font-family: "Montserrat";
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 101.927%; /* 25.482px */
    letter-spacing: -0.5px;
    margin-bottom: 7px;
  }

  .about-me .a3 {
    width: 355px;
    color: #f2f1eb;
    font-family: "Montserrat";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.24px;
    margin-bottom: 24px;
  }

  .about-me .a4 {
    color: #f2f1eb;
    font-family: "Montserrat";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.28px;

    margin-bottom: 12px;
  }

  .about-me .a5 {
    gap: 6px;

    margin-bottom: 15px;
  }

  .about-me .a5 .text {
    width: 162.92px;
    color: #f2f1eb;
    font-family: "Montserrat";
    font-size: 9.051px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.181px;
  }

  .about-me .a5 .text span {
    color: #f2f1eb;
    font-family: "Montserrat";
    font-size: 9.051px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.181px;
  }

  .about-me .a6 {
    gap: 6px;
    margin-bottom: 32px;
  }

  .about-me .a6 .text {
    width: 259.314px;
    color: #f2f1eb;
    font-family: "Montserrat";
    font-size: 9.051px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.181px;
  }

  .about-me .a6 .text span {
    color: #f2f1eb;
    font-family: "Montserrat";
    font-size: 9.051px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.181px;
  }

  .about-me .a5 img,
  .about-me .a6 img {
    width: 10.409px;
    height: 10.409px;
  }

  .about-me button.a7 {
    width: 139.523px;
    height: 21px;
    flex-shrink: 0;

    border-radius: 14.237px;
    background: linear-gradient(180deg, #fff -35.59%, #ffc803 123.73%);
    box-shadow: 0px 0px 4.271px 0px rgba(255, 255, 255, 0.7),
      0px -1.068px 3.097px 0px rgba(0, 0, 0, 0.25) inset;

    color: #000;
    font-family: "Montserrat";
    font-size: 7.491px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.15px;
  }

  .about-me .flex .right {
    margin: auto;
  }

  .about-me .right img {
    width: 279px;
    height: 291px;
    flex-shrink: 0;
    border-radius: 12.306px;
    box-shadow: 0px 6.153px 9.229px 0px rgba(0, 0, 0, 0.25);
  }
}

@media (max-width: 690px) {
  .about-me .a2 {
    font-size: 20px;
  }

  .about-me .a3 {
    width: 262px;
    font-size: 11px;
  }
}

@media (max-width: 650px) {
  .about-me {
    padding-top: 88px;
    padding-bottom: 54px;

    background: #4a4a4a;
    background-image: url("../Assets/AboutMe/Rectangle\ 25.png"),
      url("../Assets/AboutMe/astana\ 1\ \(Traced\)-mob.png");
    background-repeat: no-repeat;
    background-size: cover, contain;
    background-position: center, bottom -10px center;

    position: relative;
  }

  .about-me .flex {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .about-me .right {
    order: 1;
  }

  .about-me .left {
    order: 2;
  }

  .about-me .a1 {
    color: #f2f1eb;
    text-align: center;
    font-family: "Montserrat";
    font-size: 26.225px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    margin: 0;
    position: absolute;
    top: 37px;
    left: 50%;
    transform: translateX(-50%);
  }

  .about-me .right img {
    width: 322px;
    height: 308px;
    flex-shrink: 0;
    border-radius: 12px;
    box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.25);

    /* Ensures the image covers the container */
    object-fit: cover;

    /* Centers the image inside the container */
    object-position: center;
  }

  .about-me .a2 {
    width: 350px;
    color: #f2f1eb;
    text-align: center;
    font-family: "Montserrat";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.36px;

    margin-top: 18px;
    margin-bottom: 10px;
  }

  .about-me .a3 {
    width: 314.5px;
    color: #f2f1eb;
    text-align: center;
    font-family: "Montserrat";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.24px;
    margin: auto;
    margin-bottom: 15px;
  }

  .about-me .a4 {
    color: #f2f1eb;
    text-align: center;
    font-family: "Montserrat";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.36px;

    margin-bottom: 8px;
  }

  .about-me .a5 {
    justify-content: center;
    gap: 15px;

    margin-bottom: 10px;
    position: relative;
  }

  .about-me .a5 .text {
    width: 314.5px;
    color: #f2f1eb;
    text-align: center;
    font-family: "Montserrat";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.24px;
  }

  .about-me .a5 .text span {
    color: #f2f1eb;
    font-family: "Montserrat";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.24px;
  }

  .about-me .a6 {
    justify-content: center;
    gap: 15px;
    margin-bottom: 18.4px;
    position: relative;
  }

  .about-me .a6 .text {
    width: 314.5px;
    color: #f2f1eb;
    text-align: center;
    font-family: "Montserrat";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.24px;
  }

  .about-me .a6 .text span {
    color: #f2f1eb;
    font-family: "Montserrat";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.24px;
  }

  .about-me .a5 img,
  .about-me .a6 img {
    width: 13.726px;
    height: 13.726px;
    flex-shrink: 0;
    position: absolute;
    left: 0px;
    top: 0;
  }

  .about-me button.a7 {
    margin: auto;
    width: 208.459px;
    height: 31.375px;
    flex-shrink: 0;

    border-radius: 12px;
    background: linear-gradient(180deg, #fff -35.59%, #ffc803 123.73%);

    color: #000;
    font-family: "Montserrat";
    font-size: 11.192px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.224px;
  }

  .about-me .flex .right {
    margin: auto;
  }

  .about-me .right img {
    width: 279px;
    height: 291px;
    flex-shrink: 0;
    border-radius: 12.306px;
    box-shadow: 0px 6.153px 9.229px 0px rgba(0, 0, 0, 0.25);
  }
}
