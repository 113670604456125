.services {
  padding-top: 50px;
  padding-bottom: 93px;
  background: #4a4a4a;
  background-image: url("../Assets/Services/Rectangle\ 25.png");
  background-size: cover;
}

.services .se1 {
  color: #f1c420;
  text-align: center;
  font-family: "Montserrat";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.5px;

  margin-bottom: 13px;
}

.services .se2 {
  width: 400px;
  color: #f2f1eb;
  text-align: center;
  font-family: "Montserrat";
  font-size: 33px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 33.333px */
  letter-spacing: -1.667px;

  margin: auto;
  margin-bottom: 14px;
}

.services .se3 {
  width: 537px;

  color: #f2f1eb;
  text-align: center;
  font-family: "Montserrat";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 115%; /* 13.333px */
  letter-spacing: -0.667px;

  margin: auto;
  margin-bottom: 60px;
}

.services .container {
  display: flex;
  justify-content: center;
  gap: 13px;
}

.services .card {
  width: 230px;
  height: 400px;
  border-radius: 11px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 42.52%,
      rgba(255, 255, 255, 0.5) 111.31%
    ),
    #f2f1eb;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.services .card img {
  width: 215px;
  height: auto;
  flex-shrink: 0;
  border-radius: 5.297px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 0;
  transition: transform 0.2s ease;
}

.services .card .head {
  width: 220px;
  color: #000;
  text-align: center;
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 10.594px */
  letter-spacing: -0.53px;

  margin: auto;
  margin-top: 11px;
  margin-bottom: 0;
}

.services .card .text {
  width: 220px;
  color: #000;
  text-align: center;
  font-family: "Montserrat";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 7.945px */
  letter-spacing: -0.397px;

  margin: auto;
  margin-top: 8px;
}

.services .card .buttons {
  display: flex;
  gap: 13px;
  justify-content: center;
  margin-top: auto; /* Push buttons to the bottom */
  margin-bottom: 18px;
}

.services .card .buttons button {
  display: flex;
  width: 84px;
  height: 23px;
  justify-content: center;
  align-items: center;

  border: none;
  border-radius: 12px;
  background: #fdd84f;
  box-shadow: 0px 0.914px 2.741px 0px rgba(0, 0, 0, 0.15);

  color: #000;
  text-align: center;
  font-family: "Montserrat";
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 7.945px */
  letter-spacing: -0.397px;
}

.services .card .buttons button.b2 {
  border: 0.914px solid #8e8e8e;
  background: rgba(217, 217, 217, 0.01);
  box-shadow: 0px 0.914px 2.741px 0px rgba(0, 0, 0, 0.15);
}

.card.active {
  transform: scale(1.1);
  transition: transform 0.2s ease;
  margin: 0 18px;
}

.services .btns {
  display: flex;
  gap: 42px;
  justify-content: center;
  margin-top: 89px;
}

.services .btns button {
  background: none;
  border: none;
  border-radius: 11px;
  background: #f2f1eb;
  cursor: pointer;
}

.services .btns .indicators {
  display: flex;
  gap: 7px;
  align-items: center;
}

.services .btns .indicator {
  width: 9px;
  height: 9px;
  border-radius: 9px;
  background: rgba(217, 217, 217, 0.34);
  transition: background 0.2s ease;
}

.services .btns .indicator.active {
  width: 11px;
  height: 11px;
  border-radius: 11px;
  background: #f2f1eb;
  transition: background 0.2s ease;
}

@media (max-width: 850px) {
  .services {
    padding-top: 22px;
    padding-bottom: 34px;
    background: #4a4a4a;
    background-image: url("../Assets/Services/Rectangle\ 25.png");
    background-size: cover;
  }

  .services .se1 {
    color: #f1c420;
    text-align: center;
    font-family: "Montserrat";
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.3px;

    margin-bottom: 8px;
  }

  .services .se2 {
    width: 307px;
    color: #f2f1eb;
    text-align: center;
    font-family: "Montserrat";
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 120% */
    letter-spacing: -1.25px;

    margin: auto;
    margin-bottom: 6px;
  }

  .services .se3 {
    width: 614px;

    color: #f2f1eb;
    text-align: center;
    font-family: "Montserrat";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.6px;

    margin: auto;
    margin-bottom: 55px;
  }

  .services .container {
    display: flex;
    justify-content: center;
    gap: 17px;
  }

  .services .card {
    width: 196.29px;
    height: 271.52px;
    flex-shrink: 0;
    border-radius: 10.027px;
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 42.52%,
        rgba(255, 255, 255, 0.5) 111.31%
      ),
      #f2f1eb;
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  .services .card img {
    width: 186.5px;
    height: auto;
    flex-shrink: 0;
    border-radius: 5.013px;
    margin: auto;
    margin-top: 5px;
    margin-bottom: 0;
    transition: transform 0.2s ease;
  }

  .services .card .head {
    width: 173.891px;
    color: #000;
    text-align: center;
    font-family: "Montserrat";
    font-size: 10.027px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 10.027px */
    letter-spacing: -0.501px;

    margin: auto;
    margin-top: 11px;
    margin-bottom: 0;
  }

  .services .card .text {
    width: 178.956px;
    color: #000;
    text-align: center;
    font-family: "Montserrat";
    font-size: 7.52px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 7.52px */
    letter-spacing: -0.376px;

    margin: auto;
    margin-top: 15px;
  }

  .services .card .buttons {
    display: flex;
    gap: 12px;
    justify-content: center;
    margin-top: auto; /* Push buttons to the bottom */
    margin-bottom: 17px;
  }

  .services .card .buttons button {
    display: flex;
    width: 79.714px;
    height: 22.059px;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;

    border: none;
    border-radius: 11.241px;
    background: #fdd84f;
    box-shadow: 0px 0.865px 2.594px 0px rgba(0, 0, 0, 0.15);

    color: #000;
    text-align: center;
    font-family: "Montserrat";
    font-size: 7.52px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 7.52px */
    letter-spacing: -0.376px;
  }

  .services .card .buttons button.b2 {
    border: 0.865px solid #8e8e8e;
    background: rgba(217, 217, 217, 0.01);
    box-shadow: 0px 0.865px 2.594px 0px rgba(0, 0, 0, 0.15);
  }

  @media (min-width: 676px) {
    .card.active {
      transform: scale(1.1);
      transition: transform 0.4s ease;
      margin: 0 18px;
    }
  }

  .services .btns {
    display: flex;
    gap: 37px;
    justify-content: center;
    margin-top: 49px;
  }

  .services .btns button {
    background: none;
    border: none;
    border-radius: 9.378px;
    background: #f2f1eb;
    cursor: pointer;
  }

  .services .btns button img {
    width: 53.922px;
    height: auto;
    flex-shrink: 0;
  }

  .services .btns .indicators {
    display: flex;
    gap: 6px;
    align-items: center;
  }

  .services .btns .indicator {
    width: 7.619px;
    height: 7.619px;
    flex-shrink: 0;
    border-radius: 7.619px;
    background: rgba(217, 217, 217, 0.34);
    transition: background 0.2s ease;
  }

  .services .btns .indicator.active {
    width: 9.378px;
    height: 9.378px;
    flex-shrink: 0;
    border-radius: 9.378px;
    background: #f2f1eb;
    transition: background 0.2s ease;
  }
}

@media (max-width: 680px) {
  .services .se3 {
    width: 539px;
  }
}

@media (max-width: 675px) {
  .services .card {
    width: 275.29px;
    height: 365.52px;
  }
  .services .card img {
    width: 244.5px;
    height: auto;
    flex-shrink: 0;
    border-radius: 5.013px;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 0;
    transition: transform 0.2s ease;
  }
  .services .card .head {
    width: 243.891px;
    color: #000;
    text-align: center;
    font-family: "Montserrat";
    font-size: 17.027px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.501px;
    margin: auto;
    margin-top: 7px;
    margin-bottom: 0;
  }
  .services .card .text {
    width: 256.956px;
    color: #000;
    text-align: center;
    font-family: "Montserrat";
    font-size: 11.52px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: -0.376px;
    margin: auto;
    margin-top: 8px;
  }
  .services .card .buttons {
    display: flex;
    gap: 16px;
    justify-content: center;
    margin-top: auto;
    margin-bottom: 9px;
  }
  .services .card .buttons button {
    display: flex;
    width: 106.714px;
    height: 26.059px;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 11.241px;
    background: #fdd84f;
    box-shadow: 0px 0.865px 2.594px 0px rgba(0, 0, 0, 0.15);
    color: #000;
    text-align: center;
    font-family: "Montserrat";
    font-size: 10.52px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: -0.376px;
  }
}

@media (max-width: 580px) {
  .services .se3 {
    margin-bottom: 21px;
  }
  .services .se3 {
    width: 354px;
    margin-bottom: 53px;
  }
}

@media (max-width: 450px) {
  .services {
    padding-top: 37px;
    padding-bottom: 78px;
    background: #4a4a4a;
    background-image: url("../Assets/Services/Rectangle\ 25.png");
    background-size: cover;
  }

  .services .se1 {
    color: #f1c420;
    text-align: center;
    font-family: "Montserrat";
    font-size: 7.193px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.36px;

    margin-bottom: 4px;
  }

  .services .se2 {
    width: 307px;
    color: #f2f1eb;
    text-align: center;
    font-family: "Montserrat";
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 23.976px; /* 95.904% */
    letter-spacing: -1.25px;

    margin: auto;
    margin-bottom: 9px;
  }

  .services .se3 {
    width: 340px;

    color: #f2f1eb;
    text-align: center;
    font-family: "Montserrat";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.6px;

    margin: auto;
    margin-bottom: 38px;
  }

  .services .card {
    width: 310.333px;
    height: 399px;
    flex-shrink: 0;
    border-radius: 13.585px;
    background: #f2f1eb;
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  .services .card img {
    width: 294.5px;
    height: auto;
    flex-shrink: 0;
    border-radius: 6.793px;
    margin: auto;
    margin-top: 8px;
    margin-bottom: 0;
    transition: transform 0.2s ease;
  }

  .services .card .head {
    width: 269.167px;
    color: #000;
    text-align: center;
    font-family: "Montserrat";
    font-size: 13.585px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 13.585px */
    letter-spacing: -0.679px;

    margin: auto;
    margin-top: 18px;
    margin-bottom: 0;
  }

  .services .card .text {
    width: 270.75px;
    color: #000;
    text-align: center;
    font-family: "Montserrat";
    font-size: 10.189px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 10.189px */
    letter-spacing: -0.509px;

    margin: auto;
    margin-top: 8px;
  }

  .services .card .buttons {
    display: flex;
    gap: 19px;
    justify-content: center;
    margin-top: auto; /* Push buttons to the bottom */
    margin-bottom: 17px;
  }

  .services .card .buttons button {
    display: flex;
    width: 125.875px;
    height: 34.833px;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;

    border: none;
    border-radius: 6.793px;
    background: #fdd84f;
    box-shadow: 0px 0.865px 2.594px 0px rgba(0, 0, 0, 0.15);

    color: #000;
    text-align: center;
    font-family: "Montserrat";
    font-size: 10.189px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 10.189px */
    letter-spacing: -0.509px;
  }

  .services .card .buttons button.b2 {
    border: 1.359px solid #000;
    background: rgba(217, 217, 217, 0.01);
    box-shadow: 0px 0.865px 2.594px 0px rgba(0, 0, 0, 0.15);
  }

  .card.active {
    transform: scale(1);
    transition: transform 0.4s ease;
    margin: 0 18px;
  }

  .services .btns {
    display: flex;
    gap: 39px;
    justify-content: center;
    margin-top: 51px;
  }

  .services .btns button {
    background: none;
    border: none;
    border-radius: 9.378px;
    background: #f2f1eb;
    cursor: pointer;
  }

  .services .btns button img {
    width: 57.527px;
    height: auto;
    flex-shrink: 0;
  }

  .services .btns .indicators {
    display: flex;
    gap: 6px;
    align-items: center;
  }

  .services .btns .indicator {
    width: 7.619px;
    height: 7.619px;
    flex-shrink: 0;
    border-radius: 7.619px;
    background: rgba(217, 217, 217, 0.34);
    transition: background 0.2s ease;
  }

  .services .btns .indicator.active {
    width: 9.378px;
    height: 9.378px;
    flex-shrink: 0;
    border-radius: 9.378px;
    background: #f2f1eb;
    transition: background 0.2s ease;
  }
}
