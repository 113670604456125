.spheres {
  padding-top: 45px;
  padding-bottom: 52px;

  background-image: url("../Assets/Spheres/back.png");
  background-size: cover;
}

.spheres .s1 {
  color: #fff;
  text-align: center;
  font-family: "Montserrat";
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 36.227px */
  letter-spacing: -1.811px;

  margin-bottom: 13px;
}

.spheres .s2 {
  width: 620px;
  margin: auto;

  color: #fff;
  text-align: center;
  font-family: "Montserrat";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.2px;

  margin-bottom: 50px;
}

.spheres .container {
  display: flex;
  justify-content: center;
  gap: 28px;
  margin-bottom: 49px;
  flex-wrap: wrap;
}

.spheres .card {
  width: 200px;
  height: 160px;
  border-radius: 21.972px;
  background: #fff;
  box-shadow: 0px 4.667px 16.6px 0px rgba(255, 255, 255, 0.25);

  position: relative;
}

.spheres .card img {
  position: absolute;
  top: -32px;
  left: 50%; /* Move to the center */
  transform: translateX(-50%);
  border-radius: 56.5px;
  box-shadow: 0px 3.139px 9.783px 0px rgba(0, 0, 0, 0.15);
}

.spheres .card .head {
  width: 140px;
  color: #000;
  text-align: center;
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 10.231px */

  margin: auto;
  padding-top: 39px;
  margin-bottom: 7px;
}

.spheres .card .text {
  width: 180px;
  margin: auto;

  color: #000;
  text-align: center;
  font-family: "Montserrat";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.spheres button {
  margin: auto;
  display: flex;
  width: 208.667px;
  height: 31.406px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;

  border: none;
  border-radius: 20px;
  background: #fdd84f;
  backdrop-filter: blur(17.220321655273438px);

  color: #000;
  font-family: "Montserrat";
  font-size: 11.203px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.224px;

  cursor: pointer;
}

@media (max-width: 850px) {
  .spheres {
    padding-top: 41px;
    padding-bottom: 31px;

    background-image: url("../Assets/Spheres/back-tab.png");
    background-size: cover;
  }

  .spheres .s1 {
    color: #fff;
    text-align: center;
    font-family: "Montserrat";
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -1.25px;

    margin-bottom: 7px;
  }

  .spheres .s2 {
    width: 660px;
    margin: auto;

    color: #fff;
    text-align: center;
    font-family: "Montserrat";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.24px;

    margin-bottom: 44px;
  }

  .spheres .container {
    width: 95%;
    display: flex;
    justify-content: center;
    gap: 50px 30px;
    margin-bottom: 23px;
    flex-wrap: wrap;
    margin: auto;
    margin-bottom: 35px;
  }

  .spheres .card {
    width: 200px;
    height: 150px;
    flex-shrink: 0;
    border-radius: 20.69px;
    background: #fff;
    box-shadow: 0px 4.394px 15.631px 0px rgba(255, 255, 255, 0.25);
    position: relative;
  }

  .spheres .card img {
    position: absolute;
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    top: -27px;
    left: 50%; /* Move to the center */
    transform: translateX(-50%);
  }

  .spheres .card .head {
    width: 180px;
    color: #000;
    text-align: center;
    font-family: "Montserrat";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 9.634px */
    letter-spacing: -0.193px;

    margin: auto;
    padding-top: 38px;
    margin-bottom: 8px;
  }

  .spheres .card .text {
    width: 170px;
    margin: auto;

    color: #000;
    text-align: center;
    font-family: "Montserrat";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .spheres button {
    margin: auto;
    display: flex;
    width: 220px;
    height: 28px;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;

    border: none;
    border-radius: 15.899px;
    background: #fdd84f;
    backdrop-filter: blur(13.689126014709473px);

    color: #000;
    font-family: "Montserrat";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.178px;

    cursor: pointer;
  }
}

@media (max-width: 725px) {
  .spheres .s2 {
    width: 550px;
  }
}

@media (max-width: 566px) {
  .spheres {
    padding-top: 23px;
    padding-bottom: 57px;

    background-image: url("../Assets/Spheres/back-mob.png");
    background-size: cover;
  }

  .spheres .s1 {
    color: #fff;
    text-align: center;
    font-family: "Montserrat";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -1px;

    margin-bottom: 9px;
  }

  .spheres .s2 {
    width: 341px;
    margin: auto;

    color: #fff;
    text-align: center;
    font-family: "Montserrat";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.2px;

    margin-bottom: 41px;
  }

  .spheres .container {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 48px 23px;
    flex-wrap: wrap;
    margin: auto;
    margin-bottom: 56px;
  }

  .spheres .card {
    width: 168.683px;
    height: 125.751px;
    flex-shrink: 0;
    border-radius: 25.271px;
    background: #fff;
    box-shadow: 0px 4.394px 15.631px 0px rgba(255, 255, 255, 0.25);
    position: relative;
  }

  .spheres .card img {
    position: absolute;
    width: 64.981px;
    height: 64.981px;
    flex-shrink: 0;
    top: -32.5px;
    left: 50%; /* Move to the center */
    transform: translateX(-50%);
  }

  .spheres .card .head {
    width: 132.646px;
    color: #000;
    text-align: center;
    font-family: "Montserrat";
    font-size: 11.767px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 11.767px */
    letter-spacing: -0.235px;

    margin: auto;
    padding-top: 38px;
    margin-bottom: 4px;
  }

  .spheres .card .text {
    width: 149.515px;
    margin: auto;

    color: #000;
    text-align: center;
    font-family: "Montserrat";
    font-size: 9.627px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .spheres button {
    margin: auto;
    display: flex;
    width: 224.254px;
    height: 36.612px;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;

    border: none;
    border-radius: 24.822px;
    background: #fdd84f;
    backdrop-filter: blur(20.074630737304688px);

    color: #000;
    font-family: "Montserrat";
    font-size: 12.06px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.261px;

    cursor: pointer;
  }
}
