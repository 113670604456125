.closed {
  display: none;
}

.spinner {
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
}

.form {
  width: 85%;
  max-width: 1060px;
  height: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 30px;
  background: #241a12;
  z-index: 999;
  padding: 15px 0;

  position: fixed;
  top: 50%; /* Move down to the middle of the parent */
  left: 50%; /* Move right to the middle of the parent */
  transform: translate(-50%, -50%) scale(0.5); /* Center and scale down */
  opacity: 0; /* Initially invisible */
  animation: appear 0.7s ease forwards; /* Apply the animation */
}

/* Animation definition */
@keyframes appear {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.5); /* Start small */
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1); /* End at full size */
  }
}

.form .left {
  width: 47%;
  max-width: 506px;
  height: 494px;
  border-radius: 30px;
  background-image: url("../Assets/Form/Group\ 367.png");
  background-size: cover;
}

.form .left div {
  color: #fff;
  text-align: center;
  font-family: "Playfair Display";
  font-size: 66px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 123.364% */
  text-transform: uppercase;
  margin-top: 40px;
}

.form .right button {
  transition: transform 0.2s ease; /* Smooth animation */
}

/* Button click (active) animation */
.form .right button:active {
  transform: scale(0.95); /* Scale down slightly when clicked */
}

.form .right {
  width: 47%;
  max-width: 506px;
  height: 494px;
  background-color: #ffffff;
  border-radius: 30px;
}

.form .right .f1 {
  color: #000;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-left: 40px;
  margin-bottom: 8px;
}

.form .right .f1.r1 {
  margin-top: 48px;
}

.form .right input {
  width: 80%;
  max-width: 399px;
  height: 47px;
  flex-shrink: 0;

  border-radius: 10.143px;
  border: 0.811px solid #c5c5c5;
  background: #eee;

  padding-left: 20px;
  box-sizing: border-box; /* Включаем padding и border в ширину и высоту */

  margin-left: 40px;
  margin-bottom: 26px;

  color: #1c1c1c;
  font-family: "Montserrat";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2px;
}

.form .right button {
  color: #000;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  display: flex;
  width: 80%;
  max-width: 399px;
  height: 47px;
  justify-content: center;
  align-items: center;

  border-radius: 10px;
  border: 0.811px solid #e4d9ff;
  background: #fed544;

  margin-left: 40px;
  margin-top: 24px;
}

@media (max-width: 1100px) {
  .form .left div {
    color: #fff;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    text-transform: uppercase;
    margin-top: 40px;
  }
}

@media (max-width: 850px) {
  .spinner {
    height: 32px;
  }

  .form {
    width: 85%;
    max-width: 710px;
    height: auto;
    padding: 15px 0;
    border-radius: 20px;
  }

  .form .left {
    width: 47%;
    max-width: 339px;
    height: 330px;
    border-radius: 20px;
  }

  .form .left div {
    font-size: 44px;
    margin-top: 17px;
  }

  .form .right {
    width: 47%;
    max-width: 339px;
    height: 330px;
    border-radius: 20px;
  }

  .form .right .f1 {
    font-size: 11px;

    margin-left: 27px;
    margin-bottom: 5px;
  }

  .form .right .f1.r1 {
    margin-top: 32px;
  }

  .form .right input {
    width: 80%;
    max-width: 280px;
    height: 32px;

    border-radius: 7px;
    border: 0.5px solid #c5c5c5;

    padding-left: 14px;

    margin-left: 27px;
    margin-bottom: 17px;

    font-size: 8px;
    letter-spacing: 1px;
  }

  .form .right button {
    font-size: 11px;

    width: 80%;
    max-width: 280px;
    height: 32px;

    border-radius: 10px;
    border: 0.5px solid #e4d9ff;

    margin-left: 27px;
    margin-top: 17px;
  }
}

@media (max-width: 730px) {
  .form .left div {
    font-size: 34px;
    margin-top: 46px;
  }
}

@media (max-width: 600px) {
  .spinner {
    height: 30px;
  }

  .form {
    width: 350px;
    height: 560px;
    gap: 12px;
    border-radius: 30px;
    flex-direction: column;
  }

  .form .left {
    width: 320px;
    height: 198px;
    border-radius: 20px;
  }

  .form .left div {
    font-size: 27px;
    margin-top: 16px;
  }

  .form .right {
    width: 320px;
    height: 313px;
    border-radius: 20px;
  }

  .form .right .f1 {
    font-size: 10px;

    margin-left: 25px;
    margin-bottom: 5px;
  }

  .form .right .f1.r1 {
    margin-top: 30px;
  }

  .form .right input {
    width: 266px;
    height: 30px;

    border-radius: 7px;
    border: 0.5px solid #c5c5c5;

    padding-left: 13px;

    margin-left: 25px;
    margin-bottom: 17px;

    font-size: 8px;
    letter-spacing: 1px;
  }

  .form .right button {
    font-size: 10px;

    width: 266px;
    height: 30px;

    border-radius: 7px;
    border: 0.5px solid #e4d9ff;

    margin-top: 15px;
  }
}

@media (max-width: 370px) {
  .form {
    width: 90%;
  }
  .form .left {
    width: 95%;
    background-position: center;
  }
  .form .right {
    width: 95%;
  }

  .form .right input {
    width: 80%;
    margin-left: 20px;
  }

  .form .right button {
    width: 85%;
  }
}
