.spinner-contact {
  height: 38px;
  width: 238px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  margin-top: 41px;
}

.contacts {
  background: #2c2c2c;
  background-image: url("../Assets/Contacts/Rectangle\ 25.png"),
    url("../Assets/Contacts/Group\ 51.png");

  background-repeat: no-repeat, no-repeat;
  background-position: center, center bottom -30px;
  background-size: cover, contain;

  padding: 120px 0;
}

.contacts .flex {
  display: flex;
  gap: 71px;
  justify-content: center;
}

.contacts .l1 {
  color: #fff;
  font-family: "Montserrat";
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 40px */
}

.contacts .l2 {
  color: #fff;
  font-family: "Montserrat";
  font-size: 27px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 26.667px */

  margin-top: 18px;
}

.contacts .l3 {
  color: rgba(255, 255, 255, 0.39);
  font-family: "Montserrat";
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 16.667px */
}

.contacts .flexx {
  display: flex;
  gap: 25px;

  margin-top: 24px;
  margin-bottom: 41px;
}

.contacts input {
  color: rgba(0, 0, 0, 0.41);
  font-family: "Montserrat";
  font-size: 13.333px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 9px;
}

.contacts .flexx input {
  width: 224px;
  height: 38px;
  border-radius: 7px;
  border: 1.333px solid #fff;
  background: #fff;

  margin-top: 12px;
}

.contacts .desc {
  width: 482px;
  height: 247px;
  border-radius: 7px;
  border: 1.333px solid #fff;
  background: #fff;

  margin-top: 14px;

  color: rgba(0, 0, 0, 0.41);
  font-family: "Montserrat";
  font-size: 13.333px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 9px;

  /* Ensure the text starts at the top */
  vertical-align: top;
  /* Aligns text to the top of the textarea */
  padding: 10px; /* Optional: Add some padding for better visual appearance */
  overflow-y: auto; /* Allow vertical scrolling if needed */

  /* Ensure text wraps within the textarea */
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
}

.contacts .submit {
  display: flex;
  width: 233px;
  height: 38px;
  justify-content: center;
  align-items: center;

  border-radius: 7px;
  background: none;
  border: 2.667px solid #fff;

  color: #fff;
  text-align: right;
  font-family: "Montserrat";
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-top: 41px;
  cursor: pointer;
}

/* right */

.contacts .r1 {
  width: 265px;
  color: #fff;
  font-family: "Montserrat";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  margin-top: 129px;
  margin-bottom: 55px;
}

.contacts .info {
  display: flex;
  gap: 33px;
  align-items: center;
}

.contacts .info.bott {
  margin-bottom: 31px;
}

.contacts .info .i1 a {
  color: #fff;
  font-family: "Montserrat";
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}
.contacts .info .i1 {
  margin-bottom: 13px;
}
.contacts .info .i2 a {
  color: #fff;
  font-family: "Montserrat";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}

@media (max-width: 1050px) {
  .contacts .l1 {
    font-size: 35px;
  }

  .contacts .l2 {
    font-size: 18px;
  }

  .contacts .l3 {
    font-size: 14px;
  }

  .contacts .flexx input {
    width: 221px;
    height: 32px;
  }
  .contacts input {
    color: rgba(0, 0, 0, 0.41);
    font-family: "Montserrat";
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 15px;
  }

  .contacts .desc {
    font-size: 12px;
  }
  .contacts .r1 {
    margin-top: 99px;
  }
  .contacts .flex {
    display: flex;
    gap: 44px;
    justify-content: center;
  }
}

@media (max-width: 850px) {
  .spinner-contact {
    width: 310.014px;
    height: 50.488px;
    margin: auto;
    margin-top: 71px;
  }

  .contacts {
    background: #2c2c2c;
    background-image: url("../Assets/Contacts/Rectangle\ 25.png"),
      url("../Assets/Contacts/Group\ 51-tab.png");

    background-repeat: no-repeat, no-repeat;
    background-position: center, center bottom;
    background-size: cover, contain;

    padding: 78px 0 251px 0;
  }

  .contacts .flex {
    display: flex;
    gap: 53px;
    justify-content: center;
    flex-direction: column;
  }

  .contacts .l1 {
    color: #fff;
    text-align: center;
    font-family: "Montserrat";
    font-size: 35.145px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .contacts .l2 {
    color: #fff;
    text-align: center;
    font-family: "Montserrat";
    font-size: 17.43px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    margin-top: 12px;
  }

  .contacts .l3 {
    color: rgba(255, 255, 255, 0.39);
    text-align: left;
    font-family: "Montserrat";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .contacts .flexx {
    width: fit-content;
    margin-top: 52px;
    margin-bottom: 54px;
  }

  .contacts input {
    color: rgba(0, 0, 0, 0.41);
    font-family: "Montserrat";
    font-size: 15.715px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .contacts .flexx input {
    width: 278.014px;
    height: 35.488px;
    flex-shrink: 0;
    border-radius: 8.858px;
    border: 1.772px solid #fff;
    background: #fff;

    margin-top: 12px;
  }

  .contacts .decr {
    width: fit-content;
  }

  .hhhjj {
    width: fit-content;
    margin: auto;
  }

  .contacts .desc {
    margin: 0;
    width: 596px;
    height: 182px;
    flex-shrink: 0;
    border-radius: 8.858px;
    border: 1.772px solid #fff;
    background: #fff;

    margin-top: 14px;

    color: rgba(0, 0, 0, 0.41);
    font-family: "Montserrat";
    font-size: 15.715px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    /* Ensure the text starts at the top */
    vertical-align: top;
    /* Aligns text to the top of the textarea */
    padding: 10px; /* Optional: Add some padding for better visual appearance */
    overflow-y: auto; /* Allow vertical scrolling if needed */

    /* Ensure text wraps within the textarea */
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
  }

  .contacts .submit {
    display: flex;
    width: 310.014px;
    height: 50.488px;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;

    border-radius: 8.858px;
    border: 3.543px solid #fff;

    color: #fff;
    font-family: "Montserrat";
    font-size: 22.144px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    margin: auto;
    margin-top: 71px;
    cursor: pointer;
  }

  /* right */

  .contacts .r1 {
    width: 80%;
    max-width: 650.88px;
    color: #fff;
    text-align: center;
    font-family: "Montserrat";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    margin: auto;
    margin-top: 0;
    margin-bottom: 100px;
  }

  .contacts .info {
    display: flex;
    gap: 39px;
    align-items: center;
  }

  .contacts .info img {
    width: 75.951px;
    height: 75.951px;
    flex-shrink: 0;
  }

  .contacts .info.bott {
    margin-bottom: 41px;
  }

  .contacts .info .i1 {
    color: #fff;
    font-family: "Montserrat";
    font-size: 26.01px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    margin-bottom: 19px;
  }

  .contacts .info .i2 {
    color: #fff;
    text-align: left;
    font-family: "Montserrat";
    font-size: 20.808px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .contacts .infos {
    width: fit-content;
    margin: auto;
  }
}

@media (max-width: 690px) {
  .contacts .flexx input {
    width: 190.014px;
    height: 31.488px;
    flex-shrink: 0;
    border-radius: 8.858px;
    border: 1.772px solid #fff;
    background: #fff;
    margin-top: 12px;
  }
  .contacts input {
    color: rgba(0, 0, 0, 0.41);
    font-family: "Montserrat";
    font-size: 10.715px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 9px;
  }
  .contacts .l3 {
    color: rgba(255, 255, 255, 0.39);
    text-align: left;
    font-family: "Montserrat";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .contacts .desc {
    margin: 0;
    width: 409px;
    font-size: 10.715px;
    margin-top: 10px;
  }
  .contacts .info img {
    width: 55.951px;
    height: 55.951px;
    flex-shrink: 0;
  }
  .contacts .info .i1 {
    color: #fff;
    font-family: "Montserrat";
    font-size: 26.01px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 7px;
  }
}

@media (max-width: 490px) {
  .hhhjj {
    width: fit-content;
    margin: 0;
  }

  .contacts {
    background: #2c2c2c;
    background-image: url("../Assets/Contacts/Rectangle\ 25.png"),
      url("../Assets/Contacts/Group\ 51-mob.png");

    background-repeat: no-repeat, no-repeat;
    background-position: center, center bottom -30px;
    background-size: cover, contain;

    padding: 48px 0 67px 0;
  }

  .contacts .flex {
    display: flex;
    gap: 35px;
    justify-content: center;
    flex-direction: column;
  }

  .contacts .l1 {
    color: #fff;
    text-align: left;
    font-family: "Montserrat";
    font-size: 27.68px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .contacts .l2 {
    color: #fff;
    text-align: left;
    font-family: "Montserrat";
    font-size: 18.453px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    margin-top: 6 px;
  }

  .contacts .l3 {
    color: rgba(255, 255, 255, 0.39);
    text-align: left;
    font-family: "Montserrat";
    font-size: 11.533px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .contacts .flexx {
    display: flex;
    gap: 17px;
    justify-content: center;

    margin-top: 20px;
    margin-bottom: 28px;
  }

  .contacts input {
    color: rgba(0, 0, 0, 0.41);
    font-family: "Montserrat";
    font-size: 9.227px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .contacts .flexx input {
    width: 161.465px;
    height: 26.296px;
    flex-shrink: 0;
    border-radius: 4.613px;
    border: 0.923px solid #fff;
    background: #fff;

    margin-top: 6px;
  }

  .contacts .decr {
    width: 361.4px;
    margin: auto;
  }

  .contacts .desc {
    margin: 0;
    width: 340px;
    height: 170.692px;
    flex-shrink: 0;
    border-radius: 4.613px;
    border: 0.923px solid #fff;
    background: #fff;

    margin-top: 7px;

    color: rgba(0, 0, 0, 0.41);
    font-family: "Montserrat";
    font-size: 9.227px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    /* Ensure the text starts at the top */
    vertical-align: top;
    /* Aligns text to the top of the textarea */
    padding: 10px; /* Optional: Add some padding for better visual appearance */
    overflow-y: auto; /* Allow vertical scrolling if needed */

    /* Ensure text wraps within the textarea */
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
  }

  .contacts .submit {
    display: flex;
    width: 161.465px;
    height: 26.296px;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;

    border-radius: 4.613px;
    border: 1.845px solid #fff;

    color: #fff;
    text-align: right;
    font-family: "Montserrat";
    font-size: 11.533px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    margin: 0;
    margin-top: 28px;
    cursor: pointer;
  }

  /* right */

  .contacts .r1 {
    width: 339px;
    color: #fff;
    text-align: left;
    font-family: "Montserrat";
    font-size: 11.533px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    margin: 0;
    margin-bottom: 49px;
  }

  .contacts .info {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  .contacts .info img {
    width: 39.558px;
    height: 39.558px;
    flex-shrink: 0;
  }

  .contacts .info.bott {
    margin-bottom: 21px;
  }

  .contacts .info .i1 {
    color: #fff;
    text-align: left;
    font-family: "Montserrat";
    font-size: 13.547px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    margin-bottom: 9px;
  }

  .contacts .info .i2 {
    color: #fff;
    text-align: left;
    font-family: "Montserrat";
    font-size: 10.838px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .contacts .infos {
    width: 100%;
    margin: 0;
  }

  .contacts .cont {
    width: 90%;
    margin: auto;
  }
}

@media (max-width: 400px) {
  .contacts .flexx {
    display: flex;
    gap: 17px;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 28px;
    flex-direction: column;
  }
  .contacts .flexx input {
    width: 207.465px;
    height: 25.296px;
    flex-shrink: 0;
    border-radius: 4.613px;
    border: 0.923px solid #fff;
    background: #fff;
    margin-top: 6px;
  }
  .contacts .desc {
    margin: 0;
    width: 307px;
    height: 170.692px;
    flex-shrink: 0;
    border-radius: 4.613px;
    border: 0.923px solid #fff;
    background: #fff;
    margin-top: 7px;
    color: rgba(0, 0, 0, 0.41);
    font-family: "Montserrat";
    font-size: 9.227px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    vertical-align: top;
    padding: 10px;
    overflow-y: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
  }
}
