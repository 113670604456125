.feedbacks {
  background: #4a4a4a;
  background-image: url("../Assets/Feedbacks/image\ 85.png");
  background-size: cover;
  padding: 51px 0;
}

.feedbacks .connect {
  display: none;
}

.feedbacks .ffll {
  display: flex;
  width: fit-content;
  flex-direction: column;
  margin: auto;
}

.feedbacks .box {
  width: 85%; /* Ширина 85% по умолчанию */
  max-width: 1065px;
  margin: auto;

  border-radius: 49px;
  border: 2px solid #fff;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(5.733334064483643px);

  padding-top: 48px;
  padding-bottom: 36px;
}

.feedbacks .mobile-left {
  display: none;
}

.feedbacks .flex {
  display: flex;
  gap: 25px;
}

.feedbacks .mask {
  position: relative;
}

.feedbacks .mask .m1 {
  width: 405px;
  color: #242424;
  font-family: "Montserrat";
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 50px */

  position: absolute;
  top: -8px;
  left: 0;
}

.feedbacks .mask .m2 {
  width: 481px;
  color: #fff;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  position: absolute;
  left: 19px;
  bottom: 33px;
}

.feedbacks .right {
  display: flex;
  flex-direction: column;
  gap: 21px;

  width: 300px;
  height: 503px;
  overflow-y: scroll;
}

/*######### scrollbar ############ */
::-webkit-scrollbar {
  width: 6px; /* width of the scrollbar */
  border-radius: 15px;
}

/* Track (the area where the scrollbar runs) */
::-webkit-scrollbar-track {
  background: #d9d9d9;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background: rgba(32, 32, 40, 0.49);
  border-radius: 6px;
}

/* ######## Feedback Card ################ */
.feedbacks .feedcard {
  width: 270px;

  border-radius: 17px;
  background: #ededed;
  box-shadow: 2.667px 2.667px 6.667px 0px rgba(0, 0, 0, 0.25);

  padding-left: 15px;
  padding-top: 17px;
  padding-bottom: 17px;

  position: relative;
}

.feedcard .stars {
  display: flex;
  gap: 5px;
}

.feedcard .stars img {
  width: 16px;
  height: auto;
}

.feedcard .text {
  width: 245px;
  color: #000;
  font-family: "Montserrat";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px; /* 136.586% */
  letter-spacing: -0.366px;

  margin-top: 12px;
}

.feedcard .names {
  display: flex;
  gap: 4px;
  margin-top: 10px;
}

.feedcard .name {
  color: #000;
  font-family: "Montserrat";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.818px;
}

.feedcard .gistext {
  color: #000;
  font-family: "Montserrat";
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.405px;
}

.feedcard img.link {
  position: absolute;
  right: 5px;
  bottom: 5px;
}

/* ###### Footer ########## */
.feedbacks .footer {
  width: 825px;
  height: 82px;
  margin-left: 0;

  border-radius: 19px;
  background: #202028;
  box-shadow: 2.667px 2.667px 6.667px 0px rgba(0, 0, 0, 0.25);
  margin-top: 16px;

  display: flex;
  align-items: center;
}

.feedbacks .footer .f1 {
  width: 356px;
  color: #fff;
  font-family: "Montserrat";
  font-size: 27px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 26.667px */

  margin-left: 26px;
}

.feedbacks .footer button {
  display: flex;
  width: 158px;
  height: 42px;
  justify-content: center;
  align-items: center;

  border: none;
  border-radius: 15px;
  background: #273e47;

  color: #fff;
  font-family: "Montserrat";
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  margin-right: 14px;
  margin-left: auto;

  cursor: pointer;
}

@media (max-width: 1040px) {
  .feedbacks .flex {
    gap: 15px;
  }

  .feedbacks .mask .m1 {
    width: 405px;
    color: #242424;
    font-family: "Montserrat";
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 50px */

    position: absolute;
    top: -5px;
    left: 3;
  }

  .feedbacks .mask .m2 {
    width: 410px;
    color: #fff;
    font-family: "Montserrat";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    position: absolute;
    left: 10px;
    bottom: 38px;
  }

  .feedbacks .mask img {
    width: 430px;
  }

  .feedbacks .right {
    display: flex;
    flex-direction: column;
    gap: 21px;

    width: 300px;
    height: 416.74px;
    overflow-y: scroll;
  }

  /* ###### Footer ########## */
  .feedbacks .footer {
    width: 733px;
    height: 68px;
    margin-left: 0;

    border-radius: 19px;
    background: #202028;
    box-shadow: 2.667px 2.667px 6.667px 0px rgba(0, 0, 0, 0.25);
    margin-top: 16px;

    display: flex;
    align-items: center;
  }
  .feedbacks .footer .f1 {
    width: 356px;
    color: #fff;
    font-family: "Montserrat";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 26.667px */

    margin-left: 26px;
  }

  .feedbacks .footer button {
    display: flex;
    width: 158px;
    height: 42px;
    justify-content: center;
    align-items: center;

    border: none;
    border-radius: 15px;
    background: #273e47;

    color: #fff;
    font-family: "Montserrat";
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    margin-right: 14px;
    margin-left: auto;

    cursor: pointer;
  }
}

@media (max-width: 905px) {
  .feedbacks .box {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .feedbacks .flex {
    gap: 5px;
  }

  .feedbacks .mask .m1 {
    width: 405px;
    color: #242424;
    font-family: "Montserrat";
    font-size: 38px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 50px */

    position: absolute;
    top: -4px;
    left: 2px;
  }

  .feedbacks .mask .m2 {
    width: 381px;
    color: #fff;
    font-family: "Montserrat";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    position: absolute;
    left: 10px;
    bottom: 38px;
  }

  .feedbacks .mask img {
    width: 401px;
  }

  .feedbacks .right {
    display: flex;
    flex-direction: column;
    gap: 21px;

    width: 287px;
    height: 388.64px;
    overflow-y: scroll;
  }

  /* ###### Footer ########## */
  .feedbacks .footer {
    width: 684px;
    height: 68px;
    margin-left: 0;

    border-radius: 19px;
    background: #202028;
    box-shadow: 2.667px 2.667px 6.667px 0px rgba(0, 0, 0, 0.25);
    margin-top: 16px;

    display: flex;
    align-items: center;
  }
  .feedbacks .footer .f1 {
    width: 356px;
    color: #fff;
    font-family: "Montserrat";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 26.667px */

    margin-left: 26px;
  }

  .feedbacks .footer button {
    display: flex;
    width: 158px;
    height: 42px;
    justify-content: center;
    align-items: center;

    border: none;
    border-radius: 15px;
    background: #273e47;

    color: #fff;
    font-family: "Montserrat";
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    margin-right: 14px;
    margin-left: auto;

    cursor: pointer;
  }

  .feedbacks .feedcard {
    width: 261px;

    border-radius: 17px;
    background: #ededed;
    box-shadow: 2.667px 2.667px 6.667px 0px rgba(0, 0, 0, 0.25);

    padding-left: 12px;
    padding-top: 10px;
    padding-bottom: 8px;

    position: relative;
  }

  .feedcard .stars {
    display: flex;
    gap: 5px;
  }

  .feedcard .stars img {
    width: 16px;
    height: auto;
  }

  .feedcard .text {
    width: 245px;
    color: #000;
    font-family: "Montserrat";
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: 10px; /* 136.586% */
    letter-spacing: -0.366px;

    margin-top: 12px;
  }

  .feedcard .names {
    display: flex;
    gap: 4px;
    margin-top: 10px;
  }

  .feedcard .name {
    color: #000;
    font-family: "Montserrat";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.818px;
  }

  .feedcard .gistext {
    color: #000;
    font-family: "Montserrat";
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.405px;
  }
}

@media (max-width: 850px) {
  .feedbacks {
    background: #4a4a4a;
    background-image: url("../Assets/Feedbacks/image\ 85.png");
    background-size: cover;
    padding: 65px 0 144px 0;
  }

  .feedbacks .box {
    max-width: 95%;
    width: 652.8px;
    margin: auto;

    border-radius: 38.4px;
    background: rgba(255, 255, 255, 0.53);
    backdrop-filter: blur(7.680000305175781px);

    padding-top: 13px;
    padding-bottom: 32px;
  }

  .feedbacks .ffll {
    width: 100%;
  }

  .feedbacks .flex {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 26px;
  }

  .feedbacks .left .mask {
    display: none;
  }

  .feedbacks .mobile-left {
    display: block;
  }

  .feedbacks .mobile-left .mob-png {
    display: block;
    margin: auto;
    width: 100%;
  }

  .feedbacks .mb1 {
    width: 80%;
    max-width: 479.528px;
    color: #242424;
    text-align: center;
    font-family: "Montserrat";
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 25px */

    margin: auto;
  }

  .feedbacks .mb2 {
    width: 80%;
    max-width: 398px;
    color: #242424;
    text-align: center;
    font-family: "Montserrat";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: auto;

    margin-top: 13px;
  }

  .feedbacks .mb2 span {
    color: #242424;
    font-family: "Montserrat";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .feedbacks .footer {
    display: none;
  }

  .feedbacks .right {
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;

    width: 90%;
    max-width: 501px;
    height: 526px;
    padding-left: 0px;
    padding-bottom: 10px;
    overflow-y: scroll;
  }

  /*######### scrollbar ############ */
  ::-webkit-scrollbar {
    width: 5px; /* width of the scrollbar */
    border-radius: 18px;
  }

  /* Track (the area where the scrollbar runs) */
  ::-webkit-scrollbar-track {
    background: #d9d9d9;
    border-radius: 18px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(32, 32, 40, 0.49);
    border-radius: 18px;
  }

  /* ######## Feedback Card ################ */
  .feedbacks .feedcard {
    width: 98%;
    max-width: 488.156px;
    height: auto;
    flex-shrink: 0;

    border-radius: 28.988px;
    background: #ededed;
    box-shadow: 4.638px 4.638px 11.595px 0px rgba(0, 0, 0, 0.25);

    padding: 0;
    padding-bottom: 10px;

    position: relative;
  }

  .feedcard .stars {
    display: flex;
    gap: 6px;
    margin-left: 25px;
    margin-top: 29px;
  }

  .feedcard .stars img {
    width: 22px;
    height: auto;
  }

  .feedcard .text {
    width: 87%;
    max-width: 409.309px;
    color: #000;
    font-family: "Montserrat";
    font-size: 12.734px;
    font-style: normal;
    font-weight: 400;
    line-height: 17.393px; /* 136.586% */
    letter-spacing: -0.637px;

    margin-top: 21px;
    margin-left: 25px;
  }

  .feedcard .names {
    display: flex;
    gap: 7px;
    margin-top: 12.5px;
    margin-left: 25px;
  }

  .feedcard .names img {
    width: 37.297px;
    height: auto;
  }

  .feedcard .name {
    color: #000;
    font-family: "Montserrat";
    font-size: 17.79px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -1.423px;
  }

  .feedcard .gistext {
    color: #000;
    font-family: "Montserrat";
    font-size: 8.816px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.705px;
  }

  .feedcard img.link {
    width: 57.976px;
    height: auto;
    position: absolute;
    right: 6px;
    bottom: 6px;
  }

  .feedbacks .connect {
    display: block;
    margin-top: 44px;
  }

  .feedbacks .connect .c1 {
    width: 80%;
    max-width: 444px;
    color: #fff;
    text-align: center;
    font-family: "Montserrat";
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 25px */

    margin: auto;
    margin-bottom: 48px;
  }

  .feedbacks .connect button {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 195px;
    height: 34px;
    flex-shrink: 0;

    border: none;
    border-radius: 18.922px;
    background: #fdd84f;

    color: #000;
    font-family: "Montserrat";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

@media (max-width: 650px) {
  .feedcard .stars {
    display: flex;
    gap: 6px;
    margin-left: 25px;
    margin-top: 17px;
  }
  .feedcard .text {
    width: 87%;
    max-width: 409.309px;
    color: #000;
    font-family: "Montserrat";
    font-size: 12.734px;
    font-style: normal;
    font-weight: 400;
    line-height: 17.393px;
    letter-spacing: -0.637px;
    margin-top: 15px;
    margin-left: 25px;
  }
  .feedcard img.link {
    width: 42.976px;
    height: auto;
    position: absolute;
    right: 10px;
    bottom: 5px;
  }
}

@media (max-width: 450px) {
  .feedbacks {
    background: #4a4a4a;
    background-image: url("../Assets/Feedbacks/image\ 85.png");
    background-size: cover;
    padding: 34px 0 54px 0;
  }

  .feedbacks .box {
    width: 90%;
    max-width: 390px;
    margin: auto;

    border-radius: 20px;
    background: rgba(255, 255, 255, 0.53);
    backdrop-filter: blur(4px);

    padding-top: 7px;
    padding-bottom: 27px;
  }

  .feedbacks .flex {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 14px;
  }

  .feedbacks .left .mask {
    display: none;
  }

  .feedbacks .mobile-left {
    display: block;
  }

  .feedbacks .mobile-left .mob-png {
    display: block;
    margin: auto;
    width: 307.814px;
    height: 306.616px;
    flex-shrink: 0;
  }

  .feedbacks .mb1 {
    width: 300px;
    color: #242424;
    text-align: center;
    font-family: "Montserrat";
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 25px */

    margin: auto;
  }

  .feedbacks .mb2 {
    width: 274px;
    color: #242424;
    text-align: center;
    font-family: "Montserrat";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    margin-top: 8px;
  }

  .feedbacks .mb2 span {
    color: #242424;
    font-family: "Montserrat";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .feedbacks .footer {
    display: none;
  }

  .feedbacks .right {
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 9px;

    width: 270px;
    height: 290px;
    padding-left: 0;
    padding-bottom: 0;
    overflow-y: scroll;
  }

  /*######### scrollbar ############ */
  ::-webkit-scrollbar {
    width: 2px; /* width of the scrollbar */
    border-radius: 9px;
  }

  /* Track (the area where the scrollbar runs) */
  ::-webkit-scrollbar-track {
    background: #d9d9d9;
    border-radius: 9px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(32, 32, 40, 0.49);
    border-radius: 9px;
  }

  /* ######## Feedback Card ################ */
  .feedbacks .feedcard {
    width: 254.772px;
    height: 139.186px;
    flex-shrink: 0;

    border-radius: 15.129px;
    background: #ededed;
    box-shadow: 2.421px 2.421px 6.052px 0px rgba(0, 0, 0, 0.25);
    padding: 0;

    position: relative;
  }

  .feedcard .stars {
    display: flex;
    gap: 3px;
    margin-left: 21px;
    margin-top: 15px;
  }

  .feedcard .stars img {
    width: 11.5px;
    height: auto;
  }

  .feedcard .text {
    width: 213.621px;
    color: #000;
    font-family: "Montserrat";
    font-size: 6.646px;
    font-style: normal;
    font-weight: 400;
    line-height: 9.077px; /* 136.586% */
    letter-spacing: -0.332px;

    margin-top: 11px;
    margin-left: 21px;
  }

  .feedcard .names {
    display: flex;
    gap: 4px;
    margin-top: 6px;
    margin-left: 21px;
  }

  .feedcard .names img {
    width: 19.466px;
    height: auto;
  }

  .feedcard .name {
    color: #000;
    font-family: "Montserrat";
    font-size: 9.285px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.743px;
  }

  .feedcard .gistext {
    color: #000;
    font-family: "Montserrat";
    font-size: 4.601px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.368px;
  }

  .feedcard img.link {
    width: 30.258px;
    height: auto;
    position: absolute;
    right: 3px;
    bottom: 3px;
  }

  .feedbacks .connect {
    display: block;
    margin-top: 23px;
  }

  .feedbacks .connect .c1 {
    width: 80%;
    max-width: 335px;
    color: #fff;
    text-align: center;
    font-family: "Montserrat";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 20px */

    margin: auto;
    margin-bottom: 17px;
  }

  .feedbacks .connect button {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 33px;
    flex-shrink: 0;

    border: none;
    border-radius: 14.579px;
    background: #fdd84f;

    color: #000;
    font-family: "Montserrat";
    font-size: 16.167px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
