.card-serv {
  width: 536.622px;
  padding-bottom: 10px;
  border-radius: 17.769px;
  background: rgba(242, 241, 235, 1);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.35);
  backdrop-filter: blur(13.548815727233887px);

  position: fixed;
  z-index: 999;

  position: fixed;
  top: 50%; /* Move down to the middle of the parent */
  left: 50%; /* Move right to the middle of the parent */
  transform: translate(-50%, -50%) scale(0.5); /* Center and scale down */
  opacity: 0; /* Initially invisible */
  animation: appear 0.7s ease forwards; /* Apply the animation */

  max-height: 90vh; /* Slightly less than 100% viewport height */
  overflow-y: auto;
  padding: 20px;
}

.card-serv .exit {
  display: none;
}

/* Animation definition */
@keyframes appear {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.5); /* Start small */
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1); /* End at full size */
  }
}

.card-serv.card1 .cddd1 {
  background-image: url("../Assets/Pages/page1.png");
  background-position: center;
}

.card-serv.card2 .cddd1 {
  background-image: url("../Assets/Pages/page2.png");
  background-position: center;
}

.card-serv.card3 .cddd1 {
  background-image: url("../Assets/Pages/page3.png");
  background-position: center;
}
.card-serv.card4 .cddd1 {
  background-image: url("../Assets/Pages/page4.png");
  background-position: center;
}
.card-serv.card5 .cddd1 {
  background-image: url("../Assets/Pages/page5.png");
  background-position: center;
}

.card-serv .cddd1 {
  width: 518.257px;
  flex-shrink: 0;
  border-radius: 7.108px;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.9) -27.31%,
    rgba(0, 0, 0, 0) 128.85%
  );
  box-shadow: 0px 3.564px 2.994px 0px rgba(0, 0, 0, 0.25);

  margin: auto;
  margin-top: 0;
  padding-top: 193px;
  padding-bottom: 13px;
}

.card-serv .cd1 {
  display: flex;
  width: 487.757px;
  color: #fff;
  text-align: center;
  font-family: "Montserrat";
  font-size: 21.386px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%; /* 23.525px */
  letter-spacing: -1.069px;

  margin: auto;
}

.card-serv .cd2 {
  width: 500.832px;
  color: #000;
  text-align: center;
  font-family: "Montserrat";
  font-size: 15.693px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 11.762px */
  letter-spacing: 0;

  margin: 18px auto;
}

.card-serv ul {
  text-decoration: none;
  list-style: none;
  margin: 0;
  padding: 0;
}

.card-serv ul {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 10px;
  justify-content: center;
}

.card-serv ul li {
  padding: 10px 15px;

  border-radius: 10.693px;
  background: #fff;
  box-shadow: 0px 0px 2.994px 0px rgba(0, 0, 0, 0.25);
}

.card-serv ul li div {
  color: #000;
  text-align: center;
  font-family: "Montserrat";
  font-size: 14.693px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 11.762px */
  letter-spacing: -0.535px;
  margin: auto;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.card-serv ul li:nth-child(1) {
  width: 258.059px;
  flex-shrink: 0;
}

.card-serv ul li:nth-child(2) {
  width: 196px;
  flex-shrink: 0;
}

.card-serv ul li:nth-child(3) {
  width: 210px;
  flex-shrink: 0;
}

.card-serv ul li:nth-child(4) {
  width: 192px;
  flex-shrink: 0;
}

.card-serv ul li:nth-child(5) {
  width: 207px;
  flex-shrink: 0;
}
.card-serv ul li:nth-child(6) {
  width: 263px;
  flex-shrink: 0;
}

.card-serv button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 133.267px;
  height: 28.95px;
  flex-shrink: 0;

  border: none;
  border-radius: 12.863px;
  background: #fdd84f;
  box-shadow: 0px 0.989px 2.968px 0px rgba(0, 0, 0, 0.15);

  color: #000;
  text-align: center;
  font-family: "Montserrat";
  font-size: 13.693px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 10.693px */
  letter-spacing: -0.535px;

  margin: auto;
  margin-top: 20px;
}

@media (max-width: 850px) {
  .card-serv {
    width: 70%;
    max-width: 489.023px;
    height: 827.218px;
    border-radius: 18.792px;
    background: rgba(242, 241, 235, 0.76);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.35);
    backdrop-filter: blur(14.286605834960938px);

    position: fixed;
    z-index: 999;

    position: fixed;
    top: 50%; /* Move down to the middle of the parent */
    left: 50%; /* Move right to the middle of the parent */
    transform: translate(-50%, -50%) scale(0.5); /* Center and scale down */
    opacity: 0; /* Initially invisible */
    animation: appear 0.7s ease forwards; /* Apply the animation */
  }

  .card-serv.card1 .cddd1 {
    background-image: url("../Assets/Pages/page1.png");
  }

  .card-serv .cddd1 {
    width: 100%;
    max-width: 466.51px;
    flex-shrink: 0;
    border-radius: 7.495px;
    background: none;
    box-shadow: none;
    margin: auto;
    margin-top: 0;
    padding-top: 173px;
    padding-bottom: 20px;
  }

  .card-serv .cd1 {
    display: flex;
    width: 85%;
    max-width: 430px;
    color: #fff;
    text-align: center;
    font-family: "Montserrat";
    font-size: 22.549px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 24.804px */
    letter-spacing: -1.127px;

    margin: auto;
  }

  .card-serv .cd2 {
    width: 95%;
    max-width: 466.54px;
    color: #000;
    text-align: center;
    font-family: "Montserrat";
    font-size: 11.279px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%; /* 12.407px */
    letter-spacing: -0.564px;

    margin: 18px auto;
  }

  .card-serv ul {
    text-decoration: none;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .card-serv ul {
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
  }

  .card-serv ul li {
    padding: 9.98px 5.703px;

    border-radius: 13px;
    background: #fff;
    box-shadow: 0px 0px 3.157px 0px rgba(0, 0, 0, 0.25);

    color: #000;
    text-align: center;
    font-family: "Montserrat";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%; /* 14.3px */
    letter-spacing: -0.65px;
  }

  .card-serv ul li {
    width: 90% !important;
    max-width: 429.067px !important;
    margin: auto;
  }

  .card-serv button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 143.408px;
    height: 30px;
    flex-shrink: 0;

    border-radius: 13.564px;
    background: #fdd84f;
    box-shadow: 0px 1.043px 3.13px 0px rgba(0, 0, 0, 0.15);

    color: #000;
    text-align: center;
    font-family: "Montserrat";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 13px */
    letter-spacing: -0.65px;

    margin: auto;
    margin-top: 26px;
  }
}

@media (max-width: 450px) {
  .card-serv {
    display: flex;
    width: 100%;
    max-width: 350px;
    height: auto;
    padding: 8px;
    flex-direction: column;
    align-items: center;
    gap: 0;
    border-radius: 13.432px;
    background: rgba(242, 241, 235, 0.76);
    box-shadow: 0px 2.859px 14.296px 0px rgba(0, 0, 0, 0.35);
    backdrop-filter: blur(10.211857795715332px);

    position: fixed;
    z-index: 999;

    position: fixed;
    top: 50%; /* Move down to the middle of the parent */
    left: 50%; /* Move right to the middle of the parent */
    transform: translate(-50%, -50%) scale(0.5); /* Center and scale down */
    opacity: 0; /* Initially invisible */
    animation: appear 0.7s ease forwards; /* Apply the animation */
  }

  .card-serv .exit {
    display: block;
    position: absolute;
    top: 35px;
    right: 55px;
  }

  .card-serv .exit img {
    width: 30px;
    height: auto;
  }

  .card-serv.card1 .cddd1 {
    background-image: url("../Assets/Pages/page1.png");
    background-size: cover;
  }

  .card-serv .cddd1 {
    width: 100%;
    max-width: 333.455px;
    border-radius: 5.357px;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.9) -27.31%,
      rgba(0, 0, 0, 0) 128.85%
    );
    box-shadow: 0px 2.686px 2.257px 0px rgba(0, 0, 0, 0.25);

    margin: auto;
    margin-top: 11px;
    padding-top: 124px;
    padding-bottom: 14px;
    margin-bottom: 0;
  }

  .card-serv .cd1 {
    display: flex;
    width: 90%;
    max-width: 308.03px;
    color: #fff;
    text-align: center;
    font-family: "Montserrat";
    font-size: 16.118px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 17.73px */
    letter-spacing: -0.806px;

    margin: auto;
  }

  .card-serv .cd2 {
    width: 90%;
    max-width: 334px;
    color: #000;
    text-align: center;
    font-family: "Montserrat";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%; /* 13.2px */
    letter-spacing: -0.6px;

    margin: 13px auto;
  }

  .card-serv ul {
    text-decoration: none;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .card-serv ul {
    display: flex;
    flex-direction: column;
    gap: 9px;
    justify-content: center;
  }

  .card-serv ul li {
    padding: 9.98px 5.703px;

    border-radius: 13px;
    background: #fff;
    box-shadow: 0px 0px 3.157px 0px rgba(0, 0, 0, 0.25);

    color: #000;
    text-align: center;
    font-family: "Montserrat";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%; /* 14.3px */
    letter-spacing: -0.65px;
  }

  .card-serv ul li {
    width: 90% !important;
    max-width: 284.593px !important;
    margin: auto;
  }

  .card-serv button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 156px;
    height: 33.805px;
    flex-shrink: 0;
    border-radius: 26.695px;
    background: #fdd84f;
    box-shadow: 0px 0.746px 2.237px 0px rgba(0, 0, 0, 0.15);
    color: #000;
    text-align: center;
    font-family: "Montserrat";
    font-size: 15.292px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: -0.465px;
    margin: auto;
    margin-top: 29px;
    margin-bottom: 0;
  }
}
